*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

::-webkit-datetime-edit, ::-webkit-datetime-edit-year-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute-field, ::-webkit-datetime-edit-second-field, ::-webkit-datetime-edit-millisecond-field, ::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-1\/2 {
  left: 50%;
}

.top-1\/2 {
  top: 50%;
}

.left-12 {
  left: 3rem;
}

.-top-11 {
  top: -2.75rem;
}

.-right-8 {
  right: -2rem;
}

.z-50 {
  z-index: 50;
}

.z-10 {
  z-index: 10;
}

.-m-2 {
  margin: -.5rem;
}

.-m-1\.5 {
  margin: -.375rem;
}

.-m-1 {
  margin: -.25rem;
}

.-m-0\.5 {
  margin: -.125rem;
}

.-m-0 {
  margin: 0;
}

.-m-8 {
  margin: -2rem;
}

.-m-5 {
  margin: -1.25rem;
}

.-m-4 {
  margin: -1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mr-16 {
  margin-right: 4rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.-mb-px {
  margin-bottom: -1px;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-1\.5 {
  margin-bottom: .375rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-12 {
  height: 3rem;
}

.h-full {
  height: 100%;
}

.h-px {
  height: 1px;
}

.h-8 {
  height: 2rem;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-4\/6 {
  width: 66.6667%;
}

.w-12 {
  width: 3rem;
}

.w-96 {
  width: 24rem;
}

.w-8 {
  width: 2rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-max {
  max-width: max-content;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-xl {
  max-width: 36rem;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.select-all {
  -webkit-user-select: all;
  user-select: all;
}

.resize {
  resize: both;
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-y-4 {
  row-gap: 1rem;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}

.p-2 {
  padding: .5rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-1 {
  padding: .25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-0\.5 {
  padding: .125rem;
}

.p-0 {
  padding: 0;
}

.p-8 {
  padding: 2rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-4 {
  padding: 1rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-36 {
  padding-top: 9rem;
}

.pb-36 {
  padding-bottom: 9rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-0 {
  padding-top: 0;
}

.pb-9 {
  padding-bottom: 2.25rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.font-kanit-medium {
  font-family: Kanit, Medium;
}

.font-kanit-semibold {
  font-family: Kanit, SemiBold;
}

.font-kanit-bold {
  font-family: Kanit, Bold;
}

.font-kanit-normal {
  font-family: Kanit, Normal;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.font-normal {
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-none {
  line-height: 1;
}

.leading-normal {
  line-height: 1.5;
}

.leading-tight {
  line-height: 1.25;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-snug {
  line-height: 1.375;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-purple-600 {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity));
}

.text-gray-50 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.line-through {
  text-decoration-line: line-through;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.opacity-50 {
  opacity: .5;
}

.opacity-80 {
  opacity: .8;
}

.opacity-60 {
  opacity: .6;
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-filter {
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-1000 {
  transition-duration: 1s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.hover\:text-gray-200:hover {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.hover\:text-gray-400:hover {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

@media (min-width: 640px) {
  .sm\:mt-4 {
    margin-top: 1rem;
  }

  .sm\:max-w-xs {
    max-width: 20rem;
  }

  .sm\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .md\:mb-16 {
    margin-bottom: 4rem;
  }

  .md\:inline-block {
    display: inline-block;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:w-5\/6 {
    width: 83.3333%;
  }

  .md\:max-w-3xl {
    max-width: 48rem;
  }

  .md\:max-w-xs {
    max-width: 20rem;
  }

  .md\:max-w-4xl {
    max-width: 56rem;
  }

  .md\:max-w-6xl {
    max-width: 72rem;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .md\:pb-32 {
    padding-bottom: 8rem;
  }
}

@media (min-width: 1024px) {
  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:block {
    display: block;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-1\/4 {
    width: 25%;
  }

  .lg\:w-3\/5 {
    width: 60%;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .lg\:p-4 {
    padding: 1rem;
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:pb-8 {
    padding-bottom: 2rem;
  }

  .lg\:pb-20 {
    padding-bottom: 5rem;
  }

  .lg\:pt-12 {
    padding-top: 3rem;
  }

  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
}

/*# sourceMappingURL=index.c945d91d.css.map */
